// globally accessible namedspaced js functions
// access these as msr.function() to avoid conflicts with other libs/functions

(function ($, window, document, undefined) {
	var originalHide = $.fn.modal.Constructor.prototype.hide;
    $.fn.modal.Constructor.prototype.hide = function (e) {
		var cb = this.$element.data('callback');
		if (typeof cb === 'function') {
			cb();
		}
	    return originalHide.call(this, e);
    };

})(jq2, window, document);

var bsModalHtml = function (modalDataTarget){
	//strip off possible # if at the front
	modalDataTarget = modalDataTarget.replace(/^#/, '');
	if (jq2("#" + modalDataTarget).length === 0) {
    var bsHtml = '<div class="modal fade" id="' + modalDataTarget + '" tabindex="-1" role="dialog" aria-labelledby="' + modalDataTarget + 'Label"><a href="#" class="close-modal" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></a>';
    bsHtml += '<div class="modal-dialog">';
    bsHtml += '<div class="modal-content">';
    bsHtml += '<div class="modal-body clearfix">';
    bsHtml += '</div>';
    bsHtml += '</div>';
    bsHtml += '</div>';
    bsHtml += '</div>';
    jq2('body').append(jq2.trim(bsHtml));
  }
};

var bsmodalLaunch = function (targetSelector, title, classList, options) {
	title = title || '';
	classList = classList || '';
	options = options || {};

	bsModalHtml(targetSelector);

	var $target = jq2(targetSelector);
	var $modalBody = $target.find(".modal-body");

	$target.addClass(classList);

	if (typeof options['content'] !== "undefined") {
		var modalDataContent = options['content'].toString();
		$modalBody.empty().append(modalDataContent.trim());
	}
	if (typeof options['picture'] !== "undefined") {
		$modalBody.empty().append('<img src="'+options['picture']+'" height="auto" width="100%" />');
	}
	if (typeof options['div'] !== "undefined") {
		$modalBody.empty().append((jq2(options['div']).html() || '').trim());
	}
	if (typeof options['footer'] !== "undefined") {
		$target.find('.modal-content').append('<div class="modal-footer">'+options['footer']+'</div>');
	}
	if (typeof options['size'] !== "undefined") {
		$target.find('.modal-dialog').addClass(options['size']);
		$target.addClass(options['size'] + "-size");
	}
	if (typeof options['header'] === "undefined") {
		//first, remove any headers already there
		$target.find("div.modal-header").remove();
		$target.find('.modal-content').prepend('<div class="modal-header"><h4 class="modal-title" id="modalSmallLabel">'+title+'</h4></div>');
	}
	if (typeof options['heroimg'] !== "undefined") {
		if (typeof options['heroimgretina'] !== "undefined") {
			var srcSet = 'srcset="'+options['heroimg']+' 1x, '+options['heroimgretina']+' 2x"';
		}else{
			var srcSet = '';
		}
		$target.find('.modal-content').prepend('<img class="modal-hero-img" src="'+options['heroimg']+'" '+srcSet+' />');
	}
	if (typeof options['reload'] !== "undefined") {
		$target.addClass('no-bg-click');
	}
	if (typeof options['src'] !== "undefined") {
		var modalDataSrc = options['src'];

		$modalBody.empty().append('<iframe id="modalIframe" src="'+modalDataSrc+'" width="100%" border="0" height="100%" style="border: 0;"></iframe>');
		if (!msr.isIE()) {
			jq2("#modalIframe").css('display', 'none');
		}
		jq2('#modalIframe').load(function (e) {
			setTimeout(function () {
				if (typeof options['height'] !== "undefined") {
					jq2('#modalIframe').css('display', 'block');
					jq2('.modal-body').attr('style', 'height: ' + (options['height']) +'px;');
				} else {
					msr.recalculateIframeHeight(jq2('#modalIframe'), jq2('.modal-body'));
				}
			}, 300);
		});
	}
	if (typeof options['callback'] === "function") {
		$target.data('callback', options['callback']);
	}
	if (typeof options['data'] === "object") {
		Object.keys(options['data']).forEach(function(key) {
			$target.data(key, options['data'][key]);
		});
	}
	if (typeof options['backdrop'] !== 'undefined') {
		$target.data('backdrop', options['backdrop']);
	}

	if (typeof options["doShow"] !== "undefined" && !!options["doShow"]) {
		$target.modal("show");
		$target.addClass("show"); // had to add this for checkin to get it to show, need to ask dan about it
	}
	//console.log("bsmodalLaunch args:", targetSelector, title, classList, options);
	jq2(document).trigger("bsmodal-launch", [{targetSelector: targetSelector, title: title, classList: classList, options: options}]);

	jq2(document).on("hide.bs.modal.global-handler", targetSelector, function (e) {
		var callbacks = msr.bsmodalGetBeforeCloseListener(targetSelector) || [];
		var okToClose = callbacks.every(function (cb) {
			if (typeof cb === "function") {
				var result = cb(options);
				if (result === undefined) {
          return true;
        }
			}
    });

		if (!okToClose) {
      return false;
    }
		msr.bsmodalClearBeforeCloseListeners();
		jq2(document).off("hide.bs.modal.global-handler");
		return true;
	});
};

var msr = msr || {

	isIE: function() {
			var undef,
					v = 3,
					div = document.createElement('div'),
					all = div.getElementsByTagName('i');
			while (
					div.innerHTML = '<!--[if gt IE ' + (++v) + ']><i></i><![endif]-->',
					all[0]
			);
			return v > 4 ? v : undef;
	}
	, recalculateIframeHeight: function(iframe, modalBody){
		if (!msr.isIE()) {
			iframe.css('display', 'block');
			var windowHeight = jq2( window.parent ).height(); // Returns browser height
			iframe.contents().find('body').css('min-width', 'auto');
			iframe.contents().find('#holder').css('min-width', 'auto');
			var iframeHeight = (iframe.contents().innerHeight());
			var isIOS = false; //initiate as false
			// device detection
			if( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) isIOS = true;
			if (isIOS) {
				setTimeout(function() {
					modalBody.attr('style', 'height: ' + (windowHeight - 130) + 'px; overflow:auto; -webkit-overflow-scrolling:touch;');
					iframe.attr('height', iframeHeight);
					iframe.modal('handleUpdate');
				}, 300);
			} else {
				modalBody.attr('style', 'height: '+(iframeHeight + 30)+'px;');
			}
		}
	}
	, bsmodalLaunch: bsmodalLaunch
	, bsmodalIsVisible: function (modalDataTarget) {
		return jq2(modalDataTarget).hasClass("in");
	}
	, _BSMODAL_BEFORE_CLOSE_LISTENERS_KEY: "msr_bsmodal_before_close_listeners"
	, bsmodalClearBeforeCloseListeners : function () {
		parent[msr._BSMODAL_BEFORE_CLOSE_LISTENERS_KEY] = {};
  }
	, bsmodalRegisterBeforeCloseListener: function (targetSelector, callback) {
		if (typeof parent[msr._BSMODAL_BEFORE_CLOSE_LISTENERS_KEY] === "undefined") {
			parent[msr._BSMODAL_BEFORE_CLOSE_LISTENERS_KEY] = {};
    }
    if (typeof parent[msr._BSMODAL_BEFORE_CLOSE_LISTENERS_KEY][targetSelector] === "undefined") {
			parent[msr._BSMODAL_BEFORE_CLOSE_LISTENERS_KEY][targetSelector] = [];
		}

		parent[msr._BSMODAL_BEFORE_CLOSE_LISTENERS_KEY][targetSelector].push(callback);
  }
  , bsmodalGetBeforeCloseListener: function (targetSelector) {
		if (typeof parent[msr._BSMODAL_BEFORE_CLOSE_LISTENERS_KEY] === "undefined") {
			return [];
    }
    if (typeof parent[msr._BSMODAL_BEFORE_CLOSE_LISTENERS_KEY][targetSelector] === "undefined") {
			return [];
		}

		return parent[msr._BSMODAL_BEFORE_CLOSE_LISTENERS_KEY][targetSelector];
	}
	,bsModalHtml: bsModalHtml
	,bsmodal: function(){

			var modalDataTarget = '';
			// window.bsmodalApplied = true;

			var intInline = 0;
			var intIframe = 0;
			var intPicture = 0;
			jq2('[data-toggle="modal"]').each(function(){
				modalDataTarget = jq2(this).data('target').replace('#', '');
				var modalDataTitle = jq2(this).data('title') || jq2(this).text();
				// Build only one bsmodal-inline
				if (modalDataTarget == "bsmodal-inline"){
					if (intInline === 0){
						msr.bsModalHtml(modalDataTarget);
						intInline ++;
						return;
					}
				}
				// Build only one bsmodal-iframe
				if (modalDataTarget == "bsmodal-iframe"){
					if (intIframe === 0){
						bsModalHtml(modalDataTarget);
						intIframe ++;
						return;
					}
				}
				// Build only one bsmodal-picture
				if (modalDataTarget == "bsmodal-picture"){
					if (intPicture === 0){
						bsModalHtml(modalDataTarget);
						intPicture ++;
						return;
					}
				}
				// Build all other modals with diff data-targets
				if (modalDataTarget !== "bsmodal-picture" && modalDataTarget !== "bsmodal-iframe" && modalDataTarget !== "bsmodal-inline"){
						bsModalHtml(modalDataTarget);
				}
			});
			jq2(document).on('click', '[data-toggle="modal"]', function(e){
				e.preventDefault();

				var modalThis =	jq2(this);
				var modalDataTarget = jq2(this).data('target');
				var modalDataTitle = jq2(this).data('title') || jq2(this).text();
				var modalDataClass = jq2(this).data('class') || '';

				bsmodalLaunch(modalDataTarget, modalDataTitle, modalDataClass, modalThis.data());
			});
			jq2(".modal").on('shown.bs.modal', function(e) {

				// squirrel away relatedTarget for us to do onclose processing
				var er = jq2(e.relatedTarget);
				jq2('body').data('relatedTarget', er).css('overflow', 'hidden');

				// jq2('body').css('position', 'fixed');
				setTimeout( function (){
					if (typeof er.data('fixedscroll') !== "undefined") {
						console.log('running setTimeout check for er');
						console.log(er);
						console.log('running setTimeout check for er');
						var ert = jq2(er.data('target'));
						var heightModalHead = ert.find(".modal-header").outerHeight();
						var heightAuditHead = ert.find(".auditlog-head").outerHeight();
						var heightBoth = heightModalHead + heightAuditHead;
						if ( heightBoth === 0){
							heightBoth = '142';
						}
						ert.find(".auditlog-left").css('top', heightBoth + 'px');
						ert.find(".auditlog-right").css('top', heightBoth + 'px');
					}
				}, 10);
			});
			jq2('.modal').on('hidden.bs.modal', function (e) {
				jq2('.modal-dialog').removeClass('modal-sm modal-lg');
				jq2('.modal-header').remove();
				jq2('img.modal-hero-img').remove();
				jq2('.modal-body').css('height', '');
				jq2('.modal-body').empty();
				jq2('.modal-footer').remove();
				jq2('body').css('position', '');
				jq2('body').css('overflow', '');
				jq2('.modal-body iframe').remove();
				jq2('.modal').removeClass('no-bg-click modal-lg-size modal-sm-size drawer-right modal-hide-head');
				if (typeof jq2(e.relatedTarget).data('reload') !== "undefined") {
					location.reload(true);
				}
			});

	}
	,affixContinue: function(){

        // Define the variables in the correct order
		formnavigatorOuterHeight = jq2('.formnavigator').outerHeight();
        // Dynamic variables to be recalculated on window resize
		function allVars(){
			formnavigatorFromTop = jq2(".formnavigator__anchor").position().top + jq2(".formnavigator__anchor").outerHeight();
			formNavBottom = formnavigatorFromTop + formnavigatorOuterHeight;
			formNavLeft = jq2(".event__content").position().left;
			formNavWidth = jq2(".event__content").outerWidth();
		}
		allVars();

		jq2("#main").css('padding-bottom', formnavigatorOuterHeight);

        // Affix nav function
		function affixFormNav(){
			jq2(".formnavigator").css({
				left: formNavLeft,
				width: formNavWidth
			});
			jq2(".formnavigator").addClass('formnavigator-fixed formnavigator__shadow');
			jq2("#footer").css({
				zIndex: 3,
				position: 'relative'
			});
		}

        // unAffixFormNav
		function unAffixFormNav(){
			jq2(".formnavigator").css({
			  left: 'auto',
			  width: 'auto'
		  	});
			jq2(".formnavigator.formnavigator__required").removeClass('formnavigator__required');
			jq2(".formnavigator.formnavigator__advert").removeClass('formnavigator__advert');
			jq2(".formnavigator.formnavigator-fixed").removeClass('formnavigator-fixed formnavigator__shadow');
		}

		if (formNavBottom > jq2(window).height()){
			affixFormNav();
		}

        // Resize functionality
		jq2(window).on('resize', function(){
			  allVars();
			  if( (jq2(window).scrollTop() + jq2(window).height()) < formNavBottom) {
				 affixFormNav();
			  } else {
				 unAffixFormNav();
			  }
		});

        // Scroll functionality
		jq2(window).scroll(function() {
			if( (jq2(window).scrollTop() + jq2(window).height()) > formNavBottom) {
			   unAffixFormNav();
			} else{
			   affixFormNav();
			}
		});
	}
	,spyNav: function(){
		function navMobile(fromTop){
			var fromTop = 10;
			jq2('nav.msr-spy li a').on('click', function() {
				if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
			  		var target = jq2(this.hash);
			  		target = target.length ? target : jq2('[name=' + this.hash.slice(1) +']');
			  		if (target.length) {
						jq2('html,body').animate({
				  		scrollTop: target.offset().top + fromTop
			  			}, 300);
						return false;
			  		}
				}
		  	});

		}
		navMobile();
	}

	,flytooltip: function() {
	    $('.fly-tooltip').each(function() {
				if ( $( this ).hasClass( "dynamic" ) ) {

				}else{
					$.fly.dropdown.create(this, {
						title: $(this).data('title'),
						position: $(this).data('placement'),
	          content: "<h4>"+$(this).data('title')+"</h4>"+$(this).data('content')
	        });
				}
	    });
	}

	,loaderButton: function() {
 		jq2(".btn-loader").on('click', function() {
			jq2(this).addClass("loading");
		});
	}

	,loaderFullScreen: function(loaderTxt){
		if(loaderTxt){
			loaderTxt = loaderTxt;
		}else{
			loaderTxt = "data";
		}
		var loaderDiv = "<div class='msr-loader'><div class='msr-loader-content'><div class='msr-loader-gif'></div><h2>Processing your "+loaderTxt+"..</h2><p>Sit back and relax while we process your "+loaderTxt+". This could take several minutes, so please be patient and<br><strong><em>do not refresh or close</em></strong> this page.</p></div></div>";
		jq2("body").prepend(loaderDiv);
		jq2('html, body, .modal', parent.document).animate({
		  scrollTop: 0
		}, 300);
	}
	,loaderRemove: function(){
		$(".msr-loader").remove();
	}
	, throttle: function (limit, fn, scope) {

		var last;
		var deferTimer;

		return function () {
			var context = scope || this;
			var args = arguments;
			var now = Date.now();

			if (last && now < last + limit) {
				clearTimeout(deferTimer);
				deferTimer = setTimeout(function () {
					last = now;
					fn.apply(context, args);
				}, last + limit - now);
			} else {
				last = now;
				fn.apply(context, args);
			}
		};
	}
	// Initialise the msrFormsComponents
	, msrFormsComponents: function(element) {
		if (element.data("msr-forms-components-init") == true) {
			return;
		}
		//console.log('msrFormsComponent', element);
		element.data("msr-forms-components-init", true);

		element.find('[data-toggle="popover"]').popover({
			 container: 'body'
		});
		element.find("a.form-input-unlock").on('click', function(e){
			e.preventDefault();
			jq2(this).closest('.form-group').removeClass('form-group-locked');
			jq2(this).closest('.form-group').find('input.disabled').removeClass('disabled').prop('disabled', false).focus();
		});
		element.find("a.form-toggle").on('click', function(e){
			e.preventDefault();
			jq2(this).closest('.form-toggle-group').find('div.form-toggle').toggle();
		});
		jq2.each(element.find('[data-counter]'), function(){
			var text_max = jq2(this).data('counter');
		    jq2(this).next('label').append('<span class="form-counter">'+text_max+'</span>');
		    jq2(this).keyup(function() {
		        var text_length = jq2(this).val().length;
		        var text_remaining = text_max - text_length;
				var counter = jq2(this).next('label').find('span.form-counter');
				if ( text_remaining < 11 ){
					counter.addClass('form-counter-low');
				} else {
					counter.removeClass('form-counter-low');
				}
		        counter.html(text_remaining);
		    });
		});
	}
	// Initialise the msrForms js
	, msrForms: function () {
		jq2.validator.setDefaults({
		    highlight: function(element) {
		        jq2(element).closest('.form-group').addClass('form-group-error');
		    },
		    unhighlight: function(element) {
		        jq2(element).closest('.form-group').removeClass('form-group-error');
		    },
			onfocusout: function (element) {
        		jq2(element).valid();
    		},
		    errorElement: 'span',
		    errorPlacement: function(error, element) {
				var placement = jq2(element).data('error');
				if (placement) {
					jq2(placement).append(error);
				} else {
					// console.log(error);
					element.closest(".form-group").prepend(error);
				}
		    },
			invalidHandler: function(form, validator) {
				var errors = validator.numberOfInvalids();
				if (errors) {
					jq2(form.currentTarget).find(".btn-loader").removeClass('loading');
					jq2(form.currentTarget).find(".panel.panel-alert-danger").remove();
					jq2(form.currentTarget).prepend("<div class='panel panel-alert-danger'><div class='panel-body'><h4>You're almost ready...</h4><div class='error-list'>See fields below</div></div></div>");
					var masterHeight = jq2("#master").outerHeight() + 20;
						jq2('html,body').animate({
				          scrollTop: jq2(form.currentTarget).find(".panel.panel-alert-danger").offset().top - masterHeight
					  	}, 300);
				}else{
					console.log("No more errors");
					jq2(form.currentTarget).find(".panel.panel-alert-danger").remove();
				}
			}
		});
		jq2.extend(jq2.validator.messages, {
		    required: "Required",
		    // remote: "Please fix this field.",
		    email: "Please enter a valid email",
		    // url: "Please enter a valid URL.",
		    // date: "Please enter a valid date.",
		    // dateISO: "Please enter a valid date (ISO).",
		    number: "Enter a valid number",
		    // digits: "Please enter only digits.",
		    // creditcard: "Please enter a valid credit card number.",
		    // equalTo: "Please enter the same value again.",
		    // accept: "Please enter a value with a valid extension.",
		    // maxlength: jq2.validator.format("Please enter no more than {0} characters."),
		    // minlength: jq2.validator.format("Please enter at least {0} characters."),
		    // rangelength: jq2.validator.format("Please enter a value between {0} and {1} characters long."),
		    // range: jq2.validator.format("Please enter a value between {0} and {1}."),
		    // max: jq2.validator.format("Please enter a value less than or equal to {0}."),
			// min: jq2.validator.format("Please enter a value greater than or equal to {0}.")
			min: jq2.validator.format("Minimum is {0}")
		});
		jq2("form.msrForms").validate({
		// 	errorPlacement: function(error, element) {
	    //     if( element.is(':radio') || element.is(':checkbox')) {
	    //         error.appendTo(element.parent());
	    //     } else {
	    //         error.insertAfter(element);
	    //     }
	    // },//end errorPlacement
			// showErrors: function(errorMap, errorList) {
		    //     if (submitted) {
		    //         var summary = "You have the following errors: \n";
		    //         jq2.each(errorList, function() { summary += " * " + this.message + "\n"; });
		    //         alert(summary);
		    //         submitted = false;
		    //     }
	        // 	this.defaultShowErrors();
	    	// },
			// invalidHandler: function(form, validator) {
	        // 	submitted = true;
	    	// }
			// invalidHandler: function(form, validator) {
			// 	alert('Invalid');
			// }
		});
		jq2("a.form-input-unlock").on('click', function(){
		});

		jq2(document).on('click', ".panel-select-radio", function() {
			jq2(this).closest(".panel-body-selector-rows").find("a.btn-primary").addClass("disabled");
			jq2(this).closest(".panel-body-selector-rows").find(".panel-select-radio").removeClass("selected");
			jq2(this).addClass("selected");
		});

		jq2(document).on('click', ".panel-body-selector-rows .panel-select-radio", function(e) {
			jq2(this).find("input[type='radio']").prop('checked', true).trigger("change");
		});

		jq2(document).on({
		    'focus': function () {
				jq2(this).closest(".form-group").addClass("form-group-focused");
				jq2(this).closest(".form-group-col").addClass("form-group-col-focused");
		    },
		    'blur': function () {
				jq2(this).closest(".form-group").removeClass("form-group-focused");
				jq2(this).closest(".form-group-col").removeClass("form-group-col-focused");
		    }
		}, 'input.form-control');
		jq2(document).on({
		    'change': function () {
				jq2(this).valid();
		    }
		}, 'form.msrForms select');
		jq2(":input").inputmask();
	}
	, handleMessaging: function (containerSelector) {
		/* ===================
		Affixed messages
		=================== */
		if ( jq2(containerSelector).length ){
			function transitionComplete(){
				if ( jq2(".zebra").length ){
					$.Zebra_Pin($('.zebra'));
				}
			}
			var speed = 300;
			// init timer and stores it's identifier so it can be unset later
			var timer = setInterval(bringInMessage, speed);
			var messages =  jq2(containerSelector + ' div');
			var messageAlert =  jq2(containerSelector + ' div.alert'); // Alert messages
			var length = messages.length;
			var index = 0;

			if ( messageAlert.length ){
				// Scroll back to top, but also the parent window if done within iframe.
				jq2('html, body, .modal', parent.document).animate({
				  scrollTop: 0
				}, 300);
			}

			function bringInMessage() {
				// messages.eq(index).fadeIn();
				messages.eq(index).animate({
					opacity: 1
				}, 300);
				transitionComplete();
			     index++;
			     // remove timer after interating through all messages
			     if (index >= length) {
			         clearInterval(timer);
			     }
			}
			// Now remove the unimportant ones
			// setTimeout(function(){
			// 	jq2('#messaging div.notice').fadeOut();
			// }, 3000);

			// Click function for closing each message
			jq2(containerSelector + ' div .close-button').on("click", function(){
				jq2(this).closest("div").fadeOut(300, transitionComplete);
			});
		} // end messaging functions
	}
	,messagingTemplate: function (messagesArray, showCloseButtons) {
		showCloseButtons = showCloseButtons || false;
		var tmpl = function (obj) {
			return '<div class="' + (obj.status || "").toLowerCase() + '">' + obj.text + (showCloseButtons ? '<span class="close-button">&nbsp;</span>' : '') + '</div>';
		};
		return '<div id="messaging">' + messagesArray.map(tmpl).join('') + '</div>';
	}
	,capitalizeFirstLetter: function (string) {
	    return (string || "").charAt(0).toUpperCase() + string.slice(1);
	}
	, isLocalStorageAvailable: function () {
		/*
		adapted from https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
		BEWARE! This Modernizr approach will return false if the localStorage quota is reached. If you have functions
		dealing with localStorage cleaning, you should edit the catch statement to launch appropriate cleaning actions
		if the exception name e.name ==== 'QUOTA_EXCEEDED_ERR' (Chrome) or 'NS_ERROR_DOM_QUOTA_REACHED' (Firefox/Safari)
		or if localStorage.remainingSpace === 0 in IE
		 */
		var test = "__is_localStorage_available_test__";
		try {
			localStorage.setItem(test, test);
			localStorage.removeItem(test);
			return true;
		} catch (e) {
			return false;
		}
	}
	, isSessionStorageAvailable: function () {
		/*
		adapted from https://stackoverflow.com/questions/16427636/check-if-localstorage-is-available
		BEWARE! This Modernizr approach will return false if the localStorage quota is reached. If you have functions
		dealing with localStorage cleaning, you should edit the catch statement to launch appropriate cleaning actions
		if the exception name e.name ==== 'QUOTA_EXCEEDED_ERR' (Chrome) or 'NS_ERROR_DOM_QUOTA_REACHED' (Firefox/Safari)
		or if localStorage.remainingSpace === 0 in IE
		 */
		var test = "__is_sessionStorage_available_test__";
		try {
			sessionStorage.setItem(test, test);
			sessionStorage.removeItem(test);
			return true;
		} catch (e) {
			return false;
		}
	}
	, getBaseURL: function () {
		return window.location.protocol + '//' + window.location.host;
	}
	, recaptcha: function recaptcha(data, callback) {
		if (typeof PAGE === 'undefined' ||
			typeof PAGE.xe === 'undefined' ||
			typeof PAGE.xe.recaptcha === 'undefined') {
			// if you find this code because of this error, you need to add the xe to the model-glue event
			// and provide the xe to the PAGE js object.  See template.cfm for an example.
			console.error("PAGE.xe.recaptcha is undefined.");
		}

		/*
			`data` must have these required fields
			- recaptcha_token
			- recaptcha_action

			You may also provide these optional fields
			- dtmClientTimestamp this must be a javascript iso date if provided.  use `new Date().toISOString()`
		 */

		jq2.ajax({
			url: PAGE.xe.recaptcha,
			data: data,
			dataType: "json",
			method: "POST",
			success: function(result) {
				//console.log("analyticsLogEvent result", result);

				if (result.success) {
					if (_.isFunction(callback)) {
						callback(result.data);
					}
				} else {
					if (_.isFunction(callback)) {
						callback(undefined);
					}
				}
			},
			error: function(error) {
				console.error("recaptcha error", error);
			},
			complete: function(data) {
				//console.log("complete", data);
			},
		});
	}
	, analyticsLogEvent: function analyticsLogEvent(data, callback) {
		if (typeof PAGE === 'undefined' ||
			typeof PAGE.xe === 'undefined' ||
			typeof PAGE.xe.analyticsLogEvent === 'undefined') {
			// if you find this code because of this error, you need to add the xe to the model-glue event
			// and provide the xe to the PAGE js object.  See template.cfm for an example.
			console.error("PAGE.xe.analyticsLogEvent is undefined.");
		}

		/*
			`data` must have these required fields
			- vchCategory
			- vchAction
			- jsnData (must be a json representation of an object, can be empty)

			You may also provide these optional fields
			- gidRequestID this should be available in all views and can help you correlate multiple analytics with the same page load
			- uidParty
			- dtmClientTimestamp this must be a javascript iso date if provided.  use `new Date().toISOString()`
		 */

		// look to see if analytics is temporarily disabled
		if (PAGE.analyticsEnabled !== true) {
			return;
		}

		jq2.ajax({
			url: PAGE.xe.analyticsLogEvent,
			data: data,
			dataType: "json",
			method: "POST",
			success: function(result) {
				//console.log("analyticsLogEvent result", result);

				if (result.success) {
					if (_.isFunction(callback)) {
						callback(result.data);
					}
				} else {
					if (_.isFunction(callback)) {
						callback(undefined);
					}
				}
			},
			error: function(error) {
				console.error("analyticsLogEvent error", error);
			},
			complete: function(data) {
				//console.log("complete", data);
			},
		});
	}
	, analyticsLogEventBatch: function analyticsLogEvent(logEvents, callback) {
		if (typeof PAGE === 'undefined' ||
			typeof PAGE.xe === 'undefined' ||
			typeof PAGE.xe.analyticsLogEventBatch === 'undefined') {
			// if you find this code because of this error, you need to add the xe to the model-glue event
			// and provide the xe to the PAGE js object.  See template.cfm for an example.
			console.error("PAGE.xe.analyticsLogEventBatch is undefined.");
		}

		// look to see if analytics is temporarily disabled
		if (PAGE.analyticsEnabled !== true) {
			return;
		}

		/*
			`logEvents` must be an array of objects that have these required fields
			- vchCategory
			- vchAction
			- jsnData (must be a json representation of an object, can be empty)

			You may also provide these optional fields
			- gidRequestID this should be available in all views and can help you correlate multiple analytics with the same page load
			- uidParty
			- dtmClientTimestamp this must be a javascript iso date if provided.  use `new Date().toISOString()`
		 */

		jq2.ajax({
			url: PAGE.xe.analyticsLogEventBatch,
			data: {
        logEvents: JSON.stringify(logEvents),
      },
			dataType: "json",
			method: "POST",
			success: function(result) {
				//console.log("analyticsLogEvent result", result);

				if (result.success) {
					if (_.isFunction(callback)) {
						callback(result.data);
					}
				} else {
					if (_.isFunction(callback)) {
						callback(undefined);
					}
				}
			},
			error: function(error) {
				console.error("analyticsLogEvent error", error);
			},
			complete: function(data) {
				//console.log("complete", data);
			},
		});
	}, asyncLogAnalyticsEvent: function (category, action, data) {
    //default stuff we always want to log if available

    window.PAGE = window.PAGE || {};
		PAGE.user = PAGE.user || {};

		// look to see if analytics is temporarily disabled
		if (PAGE.analyticsEnabled !== true) {
			return;
		}

    if (PAGE.event !== undefined) {
      data.uidEvent = data.uidEvent || PAGE.event.uidEvent;
      data.uidClub = data.uidClub || PAGE.event.uidClub;
    }
    if (PAGE.user !== undefined) {
      data.isLoggedIn = data.isLoggedIn || PAGE.user.isLoggedIn;
      data.loggedIn_uidMember = data.loggedIn_uidMember || PAGE.user.uidMember;
    }

    if (msr.isSessionStorageAvailable()) {
      var analyticsBatch = JSON.parse(sessionStorage.getItem("analyticsLogEventBatch")) || [];

      analyticsBatch.push({
        vchCategory: category,
        vchAction: action,
        jsnData: JSON.stringify(data),
        dtmClientTimestamp: new Date().toISOString(),
        gidRequestID: PAGE.requestID,
        uidParty: PAGE.user.uidMember
      });

      sessionStorage.setItem("analyticsLogEventBatch", JSON.stringify(analyticsBatch));
    } else {
      //if localStorage isnt available, send immediately
      msr.analyticsLogEvent(
        {
          vchCategory: category,
          vchAction: action,
          jsnData: JSON.stringify(data),
          dtmClientTimestamp: new Date().toISOString(),
          gidRequestID: PAGE.requestID,
          uidParty: PAGE.user.uidMember
        }
      );
    }
  }, handleAnalyticsLogLinks: function () {
    jq2("a[data-analytics-log-click]").on("click", function(e) {
      var $target = jq2(e.target).closest("a[data-analytics-log-click]");
      var href = $target.attr("href");
      var linkName = $target.data("analyticsLogClick");

      msr.asyncLogAnalyticsEvent("attendee_page_views", "link_click", {
        linkName: linkName,
        href: href,
        currentPage: PAGE.mg_event
      });
    });
  }, logIncomingLinks: function () {
    var params = window.location.search.substr(1).split('&').reduce(function (q, query) {
		  var chunks = query.split('=');
		  var key = chunks[0];
		  var value = chunks[1];
		  return (q[key] = value, q);
		}, {});
    if (params.hasOwnProperty("msrae")) {
      msr.asyncLogAnalyticsEvent("attendee_page_views", "link_click", {
        linkName: params["msrae"],
        href: window.location.href,
        referrer: document.referrer,
        currentPage: PAGE.mg_event
      });
    }
  }, sendLogAnalyticsEventBatch: function () {
    if (msr.isSessionStorageAvailable()) {
      var analyticsBatch = JSON.parse(sessionStorage.getItem("analyticsLogEventBatch") || "[]");
      sessionStorage.setItem("analyticsLogEventBatch", "[]");
      if (analyticsBatch.length > 0) {
        msr.analyticsLogEventBatch(analyticsBatch);
      }
    }
  }

};

function calculateDateRange (dateFormatMask, start, qty, uom, uomPeriodType) {
	//console.log('calculateDateRange', {dateFormatMask:dateFormatMask, start: start, qty:qty, uom:uom, uomPeriodType:uomPeriodType});
	uomPeriodType = uomPeriodType || 'NONE';
	if (uomPeriodType.length === 0) {
		uomPeriodType = 'NONE';
	}

	//console.log('calculateDateRange', start, qty, uom, uomPeriodType);
	if (typeof start == "string") {
		start = moment(start, dateFormatMask);
	} else {
		start = moment(start);
	}
	switch (uom.toUpperCase()) {
		case 'TIME_MS' :
			return {start: start.toDate(), end:moment(start).add(qty, 'milliseconds').subtract(1, 'day').toDate()};
			break;
		case 'TIME_S' :
			return {start: start.toDate(), end:moment(start).add(qty, 'seconds').subtract(1, 'day').toDate()};
			break;
		case 'TIME_MIN' :
			return {start: start.toDate(), end:moment(start).add(qty, 'minutes').subtract(1, 'day').toDate()};
			break;
		case 'TIME_HR' :
			return {start: start.toDate(), end:moment(start).add(qty, 'hours').subtract(1, 'day').toDate()};
			break;
		case 'TIME_DAY' :
			return {start: start.toDate(), end:moment(start).add(qty, 'days').subtract(1, 'day').toDate()};
			break;
		case 'TIME_WK' :
			return {start: start.toDate(), end:moment(start).add(qty, 'weeks').subtract(1, 'day').toDate()};
			break;
		case 'TIME_MON' :
			switch (uomPeriodType.toUpperCase()) {
				case 'NONE':
					return {start: start.toDate(), end:moment(start).add(qty, 'months').subtract(1, 'day').toDate()};
					break;
				case 'FISCAL_YEAR':
					start = start.startOf('year');
					return {start: start.toDate(), end:moment(start).add(qty, 'months').subtract(1, 'day').toDate()};
					break;
				case 'FISCAL_QUARTER':
					start = start.startOf('quarter');
					return {start: start.toDate(), end:moment(start).add(qty, 'months').subtract(1, 'day').toDate()};
					break;
				case 'FISCAL_MONTH':
					start = start.startOf('month');
					return {start: start.toDate(), end:moment(start).add(qty, 'months').subtract(1, 'day').toDate()};
					break;
				case 'FISCAL_WEEK':
					start = start.startOf('week');
					return {start: start.toDate(), end:moment(start).add(qty, 'months').subtract(1, 'day').toDate()};
					break;
				case 'FISCAL_BIWEEK':
					throw('NotYetImplemented');
					break;
				case 'SALES_QUARTER':
					start = start.startOf('quarter');
					return {start: start.toDate(), end:moment(start).add(qty, 'months').subtract(1, 'day').toDate()};
					break;
				case 'SALES_MONTH':
					start = start.startOf('month');
					return {start: start.toDate(), end:moment(start).add(qty, 'months').subtract(1, 'day').toDate()};
					break;
				case 'CALENDAR_YEAR':
					start = start.startOf('year');
					return {start: start.toDate(), end:moment(start).add(qty, 'months').subtract(1, 'day').toDate()};
					break;
			}
			break;
		case 'TIME_YR' :
			switch (uomPeriodType.toUpperCase()) {
				case 'NONE':
					return {start: start.toDate(), end:moment(start).add(qty, 'years').subtract(1, 'day').toDate()};
					break;
				case 'FISCAL_YEAR':
					start = start.startOf('year');
					return {start: start.toDate(), end:moment(start).add(qty, 'years').subtract(1, 'day').toDate()};
					break;
				case 'FISCAL_QUARTER':
					start = start.startOf('quarter');
					return {start: start.toDate(), end:moment(start).add(qty, 'years').subtract(1, 'day').toDate()};
					break;
				case 'FISCAL_MONTH':
					start = start.startOf('month');
					return {start: start.toDate(), end:moment(start).add(qty, 'years').subtract(1, 'day').toDate()};
					break;
				case 'FISCAL_WEEK':
					start = start.startOf('week');
					return {start: start.toDate(), end:moment(start).add(qty, 'years').subtract(1, 'day').toDate()};
					break;
				case 'FISCAL_BIWEEK':
					throw('NotYetImplemented');
					break;
				case 'SALES_QUARTER':
					start = start.startOf('quarter');
					return {start: start.toDate(), end:moment(start).add(qty, 'years').subtract(1, 'day').toDate()};
					break;
				case 'SALES_MONTH':
					start = start.startOf('month');
					return {start: start.toDate(), end:moment(start).add(qty, 'years').subtract(1, 'day').toDate()};
					break;
				case 'CALENDAR_YEAR':
					start = start.startOf('year');
					return {start: start.toDate(), end:moment(start).add(qty, 'years').subtract(1, 'day').toDate()};
					break;
			}
			break;
		case 'TIME_INF' :
			return {start: start.toDate(), end:''};
			break;
		default:
			//throw?
			break;
	}

}

function clearMarketingLocalStorage() {
	if (typeof localStorage === 'undefined') {
		return;
	}

	localStorage.removeItem("panoramaId");
	localStorage.removeItem("panoramaId_exp");
	localStorage.removeItem("panoramaId_expiry");
	localStorage.removeItem("panoramaId_expiry_exp");
	localStorage.removeItem("panoramaType");
	localStorage.removeItem("panoramaType_exp");
	localStorage.removeItem("panoramaIdType");
	localStorage.removeItem("panoramaIdType_exp");
	localStorage.removeItem("_cc_id");
	localStorage.removeItem("_cc_cc");
	localStorage.removeItem("_cc_aud");
}

jq2(document).ready(function(){

	// msrForms
	jq2('[data-toggle="tooltip"]').tooltip();
	jq2('[data-datepicker]').daterangepicker({
		showDropdowns: true
	}); // http://www.daterangepicker.com/
	jq2('[data-datepicker="range"]').daterangepicker();
	jq2('[data-datepicker="range-time"]').daterangepicker({
		timePicker: true,
	  timePickerIncrement: 10,
		locale: {
      format: 'MM/DD/YYYY h:mm A'
    }
	});
	jq2('[data-datepicker="single"]').daterangepicker({
		singleDatePicker: true
	});
	jq2('[data-datepicker="single-time"]').daterangepicker({
		singleDatePicker: true,
		timePicker: true,
	  timePickerIncrement: 10,
		locale: {
      format: 'MM/DD/YYYY h:mm A'
    }
	});

	jq2('[data-toggle="popover"]').popover({
		 container: 'body'
	});
	jq2('body').on('click', function (e) {
    	jq2('[data-toggle="popover"]').each(function () {
        //the 'is' for buttons that trigger popups
        //the 'has' for icons within a button that triggers a popup
		    if (!jq2(this).is(e.target) && jq2(this).has(e.target).length === 0 && jq2('.popover').has(e.target).length === 0) {
		        jq2(this).popover('hide');
		    }
    	});
	});

	jq2('.form-file-question .uploadcare-widget-button-open, .panel-vehicle').on('click', function(){
        jq2('.modal, .modal-body', parent.document).animate({
            scrollTop: 0
        }, 300);
    });

	msr.handleMessaging("#messaging");

	// jq2("a.form-input-unlock").on('click', function(){
	// });

	// [#116826261] Preventing Safari's 'back-forward-cache' issue, so that loader button doesn't stay in loading state
	jq2(window).bind("pageshow", function(event) {
		if (event.originalEvent.persisted) {
			$(".btn-loader").removeClass("loading");
			$(".msr-loader").remove();
		}
	  });

	jq2('#logout').click(function clearAuthorizedClubs(){
		if (typeof localStorage !== 'undefined') { localStorage.removeItem('authorized-organizations') }
		if (typeof FS !== 'undefined') { FS.clearUserCookie() }
		if (posthog) { posthog.reset() }
	});

	function clearNonEssentialCookies() {
		const consentCookieName = 'termsfeed_pc1_vendors_accepted';
		const cookieMatch = document.cookie.match('(^|;)\\s*' + consentCookieName + '\\s*=\\s*([^;]+)');

		// cookie doesn't exist yet, nothing to do. the noticeBannerClosed event will fire again when the cookie is set
		if (!cookieMatch) {
			return
		}

		const consentSelection = JSON.parse(decodeURIComponent(cookieMatch.pop())).accepted_levels;
		const shouldClear = consentSelection.length !== 5; // there are 5 categories they can opt out of. if they've opted out of any, we should clear

		// If opted out then navigate to a CF page that applies privacy choices and redirects back
		// to the current
		if (shouldClear) {
			// marketing is only enabled if both advertising and marketing consent is given
			const rejectedMarketing = !consentSelection.includes('marketing') || !consentSelection.includes('advertising');
			if (rejectedMarketing) {
				clearMarketingLocalStorage();
			}

			window.location = '/index.cfm/event/applyPrivacySelection';
		}
	}

	/*
    handle clearing of non-essential cookies on initial cookie consent based on events from TermsFeed.

    what we're doing is listening for the acceptedSome and rejectedAll events from the notice banner (which are
    fired when the user makes a selection, but before the banner is closed and cookies are set), and then listening
    for the banner to be closed, which is when we know the cookies have been set and we can act on the user's selection.
    when we hear the noticeBannerClosed event, we want to remove the listener to prevent it from being called multiple
    times.
  */
		function noticeBannerClosedListener() {
			document.removeEventListener(
				'noticeBannerClosed',
				noticeBannerClosedListener
			);
			clearNonEssentialCookies();
		}
		['acceptedSome', 'rejectedAll'].forEach(function (event) {
			document.addEventListener(event, function () {
				document.addEventListener(
					'noticeBannerClosed',
					noticeBannerClosedListener
				);
			});
		});

	msr.handleAnalyticsLogLinks();
	msr.logIncomingLinks();
	setInterval(msr.sendLogAnalyticsEventBatch, 1000 * 10);

});
